body {
  font-size: 16px;
  margin: 0;
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

select {
  max-width: 100%;
  margin-bottom: 1rem;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1px
}

@media (max-width: 1199px) {

.calendar {
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr)
}
  }

@media (max-width: 767px) {

.calendar {
    grid-template-columns: repeat(2, 1fr)
}
  }

@media (max-width: 479px) {

.calendar {
    grid-template-columns: repeat(1, 1fr)
}
  }

.dayHeading {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0.25em;
  line-height: 1.25
}

@media (max-width: 1199px) {

.dayHeading {
    display: none
}
  }

.day {
  box-shadow: 0 0 0 1px #000
}

@media (max-width: 1199px) {

.day {
    grid-column-start: auto !important;
    box-shadow: none
}
  }

.day h2 {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0.25em;
    line-height: 1.25
  }

.day h2 span {
      display: none
    }

@media (max-width: 1199px) {

.day h2 span {
        display: inline
    }
      }

.event {
  font-size: 14px;
  background: #dddddd;
  line-height: 1.5
}

.event:nth-child(2n) {
    background: #eeeeee
  }

.event:nth-child(2n) .more_info {
      background: #cccccc;
    }

.event button {
    font-size: inherit;
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    cursor: pointer;
    flex: 1 1 0;
  }

.event .event_summary {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0.25em;
  }

.event .more_info {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    background: #bbbbbb;
    padding: 0.25em;
  }

.event .youtube_embed {
    flex: 1 0 100%;
    position: relative;
    padding-bottom: 56.25%
  }

.event .youtube_embed iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

.movies {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
  grid-gap: 1rem;
}

.movie {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  border: 1px solid #000000;
  position: relative
}

.movie button {
    width: 100%;
    height: 100%;
    font-weight: 700;
    font-size: 1.5em;
    background-color: #000000;
    color: #ffffff;
    border: none;
    padding: 0.25em 0.5em;
    cursor: pointer
  }

.movie button:hover {
      opacity: 0.8;
    }

.movie button:active {
      opacity: 0.6;
    }

.movie .youtube {
    position: absolute;
    top: 0;
    right: 0.25em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
